window.addEventListener('load', () => {
  const heroSection = document.querySelector('.hero.gutenberg-block');
  const headerHeight = document.querySelector("header.header").offsetHeight;
  if(!heroSection) return;

  const heroArrow = heroSection.querySelector('.hero__arrow');

  heroArrow.addEventListener('click', () => {
    const target = heroSection.nextElementSibling;

    if(target) {
      const position = window.pageYOffset + target.getBoundingClientRect().top - headerHeight;
      window.scroll({ top: position, behavior: "smooth" });
    }   
  });
});